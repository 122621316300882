import { Typography, TypographyProps } from "@mui/material";

const QuoteText = (props: TypographyProps) => (
	<Typography color="text" variant="inherit" fontStyle={"inherit"} textAlign={"inherit"} display={"inline"}>
		{props?.children}
	</Typography>
);

const QuoteAuthor = (props: TypographyProps) => (
	<Typography color="text.secondary" variant="inherit" fontStyle={"inherit"} textAlign={"inherit"} display={"inline"} {...props}>
		{props?.children}
	</Typography>
);


const next4DigitYear = new Date().getFullYear() + 1;
export const Quote = ({ quoteAuthor, attributionString, children, ...rest }: { quoteAuthor: string, attributionString?: string } & TypographyProps) => (
	<Typography variant="subtitle1" fontStyle={"italic"} textAlign={"center"} {...rest}
		sx={{ borderLeft: "4px solid", borderColor: "secondary.main", pl: 2 }}
		ml={0}
		pl={0}
	>
		<QuoteText>"{children}"</QuoteText>
		<QuoteAuthor fontStyle={"normal"} > - {quoteAuthor}, {!!attributionString ? attributionString : (`(likely early ${next4DigitYear})`)} </QuoteAuthor>
	</Typography >
);