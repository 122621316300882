import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { getDesignTokens } from "./theme";
import { FAQ, Home } from "./pages";
import { AnalyticsUtils } from "./utils";

AnalyticsUtils.initialize();


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
  },
]);

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<PaletteMode>(
    prefersDarkMode ? "dark" : "light"
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        console.log("toggleColorMode");
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} fallbackElement={<div>Loading...</div>} />
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
