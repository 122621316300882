import { Dialog, Paper, Stack, Typography, TextField, Button, DialogProps, List, Box } from "@mui/material";
import { PrimaryWord, SecondaryWord, Quote } from "./";
import { useRef, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";

import { ACTIONS, AnalyticsUtils, CATEGORIES, LABELS } from "../utils/AnalyticsUtils";
const { sendEvent } = AnalyticsUtils;



//Example Curl: curl -X POST https://nvg2b4dvnb.execute-api.us-east-1.amazonaws.com/stashspace/signup -H "Content-Type: application/json" -d '{"email": "vango85@gmail.com"}'
const postEmail = async (email: string) => {
	const url = "https://nvg2b4dvnb.execute-api.us-east-1.amazonaws.com/stashspace/signup";
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ email }),
	});

	return response;
};


type SignupDialogProps = DialogProps & {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const SignupDialog = (props: SignupDialogProps) => {
	const { open, setOpen, ...rest } = props;
	const [loading, setLoading] = useState(false);
	const [signedUpEmail, setSignedUpEmail] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const neutralizeBack = (callback = () => { }) => {
		window.history.pushState({
			fromModal: true,
		}, "", window.location.href);
		window.onpopstate = () => {
			window.history.pushState({
				fromModal: true,
			}, "", window.location.href);
			callback();
		};
	};
	const revivalBack = () => {
		window.onpopstate = () => { };
		window.history.back();
	};

	useEffect(() => {
		const email = localStorage.getItem('signedUpEmail');
		setSignedUpEmail(email);
	}, []);

	useEffect(() => {
		if (open) {
			sendEvent(CATEGORIES.MODAL_INTERACTION, ACTIONS.OPEN, LABELS.MODAL_INTERACTION.SIGNUP_MODAL);
			inputRef.current?.focus();
		}
	}, [open]);

	useEffect(() => {
		if (open) {
			neutralizeBack(() => setOpen(false));
		} else {
			if (window.history.state?.fromModal) {
				revivalBack();
			}
		}
	}, [open, setOpen]);

	const sanitize = (email?: string) => {
		if (!email) return "";
		return email.trim().toLowerCase();
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		sendEvent(CATEGORIES.FORM_SUBMISSION, ACTIONS.SUBMIT, LABELS.FORM_INTERACTION.SIGNUP_FORM);

		const email = sanitize(inputRef?.current?.value);


		if (!isValidEmail(email)) {
			setError("Please enter a valid email address.");
			return;
		}

		if (email) {
			setLoading(true);
			const response = await postEmail(email);
			if (response.ok) {
				setSignedUpEmail(email);
				localStorage.setItem('signedUpEmail', email);
			} else {
				alert("Failed to sign up. Please try again later.");
			}
			setLoading(false);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		// We don't want to show an error if submission hasn't been attempted yet
		if (!error) {
			return;
		}

		if (isValidEmail(event?.target?.value)) {
			setError(null);
		} else {
			setError("Please enter a valid email address.");
		}
	}

	const isValidEmail = (email?: string | null) => {
		if (!email) return false;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return (emailRegex.test(email || ""));
	}

	return (
		<Dialog
			aria-labelledby="signup-dialog-title"
			aria-describedby="signup-dialog-description"
			onClose={() => {
				setOpen(false)
				sendEvent(CATEGORIES.MODAL_INTERACTION, ACTIONS.CLOSE, LABELS.MODAL_INTERACTION.SIGNUP_MODAL);
			}}
			open={open}
			fullWidth
			{...rest}
		>
			<Paper elevation={3} >
				<form onSubmit={handleSubmit}>
					<Stack spacing={2} m={4}>
						<Typography variant="h4">
							Start <PrimaryWord>Stash</PrimaryWord>-ing
						</Typography>

						{isValidEmail(signedUpEmail) ? (
							<>
								<Quote quoteAuthor="Elon Musk" attributionString="(Near Future™)" textAlign="left">
									"StashSpace knew what I was looking for before me!"
								</Quote>
								<Typography variant="h6" sx={{ mt: 2 }}>
									<SecondaryWord>Thank you</SecondaryWord> for signing up, we'll be in touch!
								</Typography>
								<Button variant="contained" onClick={() => setOpen(false)}>
									Can't Wait!
								</Button>
							</>
						) : (
							<Stack spacing={2}>
								<Stack>
									<Typography variant="h6">
										<SecondaryWord>Join</SecondaryWord> the waitlist
									</Typography>
									<List>
										<Typography>
											<SecondaryWord>○</SecondaryWord> Early access to StashSpace.
										</Typography>
										<Typography>
											<SecondaryWord>○</SecondaryWord> Help shape the future of StashSpace.
										</Typography>
										<Typography>
											<SecondaryWord>○</SecondaryWord> No unnecessary emails.
										</Typography>
									</List>
								</Stack>
								<TextField
									{...{ error: !!error, helperText: error }}
									fullWidth
									placeholder="Your Email"
									autoFocus
									onChange={handleChange}
									inputRef={inputRef}
									onFocus={() => {
										sendEvent(CATEGORIES.FORM_SUBMISSION, ACTIONS.FOCUS, LABELS.INPUT_INTERACTION.EMAIL_INPUT);
									}}
									onBlur={() => {
										sendEvent(CATEGORIES.FORM_SUBMISSION, ACTIONS.BLUR, LABELS.INPUT_INTERACTION.EMAIL_INPUT);
									}}
								/>
								<LoadingButton
									endIcon={<Send />}
									loading={loading}
									loadingPosition="end"
									variant="contained"
									type="submit"
								>
									Join waitlist
								</LoadingButton>
							</Stack >
						)}

					</Stack>
				</form>
			</Paper>
		</Dialog >
	);
};
