import { Box, BoxProps, Link, Stack } from "@mui/material";
import { GradientTypography } from "./GradientTypography";

type BounceSlideProps = {
	direction: "left" | "right";
} & BoxProps;

const BounceSlide = (props: BounceSlideProps) => {
	const { direction } = props;

	// Determine initial and bounce directions based on the provided direction
	const startingX = direction === "right" ? "-100vw" : "100vw";
	const bounceIn = direction === "right" ? "-20px" : "20px";
	const animationName = `slideAndBounce-${direction}`;

	return (
		<Box
			sx={{
				// Start with the element off-screen on the x-axis, based on direction
				transform: `translateX(${startingX})`, // Starts off the edge of the screen
				animation: `${animationName} 0.8s ease-out forwards`,
				[`@keyframes ${animationName}`]: {
					"0%": { transform: `translateX(${startingX})` }, // Starts off-screen
					"70%": { transform: "translateX(0)" }, // Slides into view
					"80%": { transform: `translateX(${bounceIn})` }, // First bounce outward
					"100%": { transform: "translateX(0)" }, // End at the final state
				},
			}}
		>
			{props.children}
		</Box>
	);
};

export const StashSpace = () => (
	<Link href="/" color="inherit"
		underline="none"
	>
		<Stack direction="row" spacing={2} justifyContent="center"
		>
			<BounceSlide direction="right">
				<GradientTypography>Stash</GradientTypography>
			</BounceSlide>
			<BounceSlide direction="left">
				<GradientTypography>Space</GradientTypography>
			</BounceSlide>
		</Stack>
	</Link>
);