import { Box, Typography, TypographyProps, useTheme } from "@mui/material";
import { forwardRef } from "react";

export const GradientTypography = forwardRef(
  (props: TypographyProps, ref: React.Ref<HTMLDivElement>) => {
    const { children } = props;

    const theme = useTheme();

    return (
      <Typography
        ref={ref}
        sx={{
          background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontSize: "3rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
        m={0}
        p={0}
        width={"auto"}
        {...props}
      >
        {children}
      </Typography>
    );
  }
);
