import {
  Alert,
  Box,
  BoxProps,
  Button,
  Container,
  Dialog,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import {
  GradientTypography,
  SellingPoints,
  PrimaryWord,
  Funnel,
  Footer,
  Quote,
  SecondaryWord,
  SignupDialog,
  StashSpace,
} from "../components";
import { BackgroundGrid } from "../components";
import { useState } from "react";

const Hero = (props: BoxProps) => (
  <Box {...props}>
    <Stack alignItems="center">
      <StashSpace />
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h5" textAlign="center">
          Making your screenshots <SecondaryWord>Searchable Memories</SecondaryWord>
        </Typography>
        <Quote quoteAuthor="Jeff Bezos" textAlign={"left"}>
          The world's most powerful bookmark
        </Quote>
      </Stack>
    </Stack>
    <Stack spacing={2} direction="column" mt={2} mb={4}>
      <BackgroundGrid />

      <Typography
        variant="body1"
        textAlign={"center"}
        fontStyle={"italic"}
        fontWeight={"bold"}
      >
        Make anything a <PrimaryWord>Stash</PrimaryWord> that is automatically
        organized. Transform anything into an intuitively searchable memory.
        Start <PrimaryWord>Stash</PrimaryWord>-ing today.
      </Typography>
    </Stack>
  </Box>
);

export const Home = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Container maxWidth="lg">
        <Stack mb={2} mx={0}>
          <SignupDialog open={open} setOpen={setOpen} />
          <Hero />
          <SellingPoints />
          <Funnel />

          <Button
            sx={{ position: "sticky", bottom: "1rem", width: "100%" }}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Start Stashing
          </Button>
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};
