import { Box, BoxProps } from "@mui/material";

type ImageProps = {
  src: string;
  alt?: string;
} & BoxProps;

export const Image = (props: ImageProps) => {
  return <Box component="img" {...props} />;
};
