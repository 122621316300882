import { Box, BoxProps, Paper } from "@mui/material";
import { Image } from "./Image";
import BackgroundGridImage from "../assets/images/background-grid-3.png";

export const BackgroundGrid = (props: BoxProps) => {
  return (
    <Paper elevation={3} sx={{ overflow: "hidden" }}>
      <Box
        position="relative"
        {...props}
        sx={{
          ...props.sx,
          overflow: "hidden",
        }}
      >
        <Image
          src={BackgroundGridImage}
          sx={{
            objectFit: "cover",
            objectPosition: "top center",
            width: {
              xs: "140%",
              sm: "130%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
            height: {
              xs: "280px",
              sm: "420px",
              md: "540px",
              lg: "640px",
              xl: "720px",
            },
            position: "relative",
            left: {
              xs: "50%",
              sm: "50%",
            },
            transform: {
              xs: "translateX(-50%)",
              sm: "translateX(-50%)",
            },
            overflow: "hidden",
          }}
        />
        <Box
          sx={{
            width: "60%",
            position: "absolute",
            top: {
              xs: "50%",
              sm: "45%",
              md: "40%",
              lg: "35%",
              xl: "40%",
            },
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Paper>
  );
};
