import ReactGA from "react-ga4";

const isProduction = process.env.NODE_ENV === "production";
const MEASUREMENT_ID = "G-LCNFCH1RLG";

export const CATEGORIES = {
	NAVIGATION: "Navigation",
	USER_INTERACTION: "User Interaction",
	FORM_SUBMISSION: "Form Submission",
	MODAL_INTERACTION: "Modal Interaction",
	INPUT_INTERACTION: "Input Interaction",
};

export const ACTIONS = {
	CLICK: "Click",
	SUBMIT: "Submit",
	CLOSE: "Close",
	OPEN: "Open",
	FOCUS: "Focus",
	BLUR: "Blur",
};

export const LABELS = {
	NAVIGATION: {
		HOME: "Home",
		FAQ: "FAQ",
	},
	USER_INTERACTION: {
		DARK_MODE: "Dark Mode",
		MODAL: "Modal",
	},
	FORM_INTERACTION: {
		SIGNUP_FORM: "Signup Form",
	},
	MODAL_INTERACTION: {
		SIGNUP_MODAL: "Signup Modal",
	},
	INPUT_INTERACTION: {
		EMAIL_INPUT: "Email Input",
	},
};

export const AnalyticsUtils = {
	initialize: () => {
		if (!isProduction) {
			console.log(`Skipping Google Analytics initialization in ${process.env.NODE_ENV} environment.`)
			return;
		}
		ReactGA.initialize(MEASUREMENT_ID);
	},
	sendPageView: (path: string) => {
		if (!isProduction) {
			console.log(`Skipping Google Analytics page view in ${process.env.NODE_ENV} environment.`)
			return;
		}
		ReactGA.send({ hitType: "pageview", page: path });
	},
	sendEvent: (category: string, action: string, label?: string, value?: number) => {
		if (!isProduction) {
			console.log(`Skipping Google Analytics event in ${process.env.NODE_ENV} environment.`)
			return;
		}
		ReactGA.send({ hitType: "event", eventCategory: category, eventAction: action, eventLabel: label, eventValue: value });
	}
};