import { PaletteMode, PaletteOptions, ThemeOptions } from "@mui/material";

const lightTheme: PaletteOptions = {
  primary: {
    main: "#7a41ff",
  },
  secondary: {
    main: "#ff4f98",
  },
  text: {
    primary: "#000",
    secondary: "rgba(255, 255, 255, 0.2)",
  },
};

const darkTheme: PaletteOptions = {
  primary: {
    main: "#7a41ff",
  },
  secondary: {
    main: "#ff4f98",
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
  },
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const isLightMode = mode === "light";
  const theme = isLightMode ? lightTheme : darkTheme;

  return {
    typography: {
      fontFamily: "Montserrat",
      // fontFamily: 'Roboto, sans-serif',
      // fontFamily: "Arial, sans-serif",
    },
    palette: {
      mode,
      ...theme,
    },
  };
};
