import { Inventory2, Search, TouchApp } from "@mui/icons-material";
import { Stack, SvgIconProps, Typography } from "@mui/material";

import { PrimaryWord, SecondaryWord } from "./";

export const SellingPoints = () => {
  const sellingPointIconStyles = {
    fontSize: "large",
    sx: {
      borderRadius: "50%",
      bgcolor: "secondary.main",
      color: "text.primary",
      padding: "5px",
      marginRight: "10px",
    },
  } as SvgIconProps;

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="flex-start">
        <TouchApp {...sellingPointIconStyles} />
        <Stack alignItems="flex-start">
          <Typography variant="h6">
            <SecondaryWord>Save: </SecondaryWord> Tap to{" "}
            <PrimaryWord>Stash</PrimaryWord>
          </Typography>
          <Typography variant="body1">
            Instantly turn any screenshot, link, or digital item into a{" "}
            <PrimaryWord>Stash</PrimaryWord> with just a tap.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="flex-start">
        <Inventory2 {...sellingPointIconStyles} />
        <Stack alignItems="flex-start">
          <Typography variant="h6">
            <SecondaryWord>Store: </SecondaryWord> Automatically organized
          </Typography>
          <Typography variant="body1">
            Forget manual sorting. Every <PrimaryWord>Stash</PrimaryWord> is
            automatically categorized and searchable.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="flex-start">
        <Search {...sellingPointIconStyles} />
        <Stack alignItems="flex-start">
          <Typography variant="h6">
            <SecondaryWord>Find: </SecondaryWord>
            Memory-First Search
          </Typography>
          <Typography variant="body1">
            A search engine for your memories. A{" "}
            <PrimaryWord>Stash</PrimaryWord> that remembers ‘That game I played
            at Martin’s’ finds it immediately—accurate, effortless, and precise.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
