import FunnelStart from "../assets/images/funnel-start.png";
import FunnelMiddle from "../assets/images/funnel-middle.png";
import Stacked from "../assets/images/stacked.png";
import { Paper, Grid, Typography, Stack, Box, Container } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Image } from "./Image";

export const Funnel = () => {
  return (
    <Box width={"100%"} display="flex" justifyContent="center">
      <Stack
        spacing={2}
        m="1rem"
        width={{ xs: "50%", sm: "50%", md: "100%" }}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        direction={{ xs: "column", sm: "column", md: "row" }}
      >
        <Image width={"100%"} src={FunnelStart} alt="Funnel Start" />
        <ArrowDownwardIcon
          fontSize="large"
          sx={{
            transform: {
              xs: "rotate(0deg)",
              sm: "rotate(0deg)",
              md: "rotate(-90deg)",
            },
          }}
        />
        <Image width={"100%"} src={FunnelMiddle} alt="Funnel Middle" />
        <ArrowDownwardIcon
          fontSize="large"
          sx={{
            transform: {
              xs: "rotate(0deg)",
              sm: "rotate(0deg)",
              md: "rotate(-90deg)",
            },
          }}
        />
        <Image width={"100%"} src={Stacked} alt="Funnel End" />
      </Stack>
    </Box>
  );
};
