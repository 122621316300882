import * as React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
} from "@mui/icons-material";

export const Footer = () => {
  return (
    <Box
      component="footer"

      width={"100%"}
      bottom={0}
      sx={{ bgcolor: "primary.dark", color: "white", py: 6 }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              StashSpace.io
            </Typography>
            <Typography variant="subtitle1" color="inherit" sx={{ mt: 2 }}>
              Helping you access your memories.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              Resources
            </Typography>
            <Box component="ul" sx={{ listStyle: "none", m: 0, p: 0, mt: 1 }}>
              <li>
                <Link href="/FAQ" color="inherit">
                  FAQ
                </Link>
              </li>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit">
              Follow Us
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Link href="#" color="inherit" sx={{ mr: 2 }}>
                <FacebookIcon />
              </Link>
              <Link href="#" color="inherit" sx={{ mr: 2 }}>
                <TwitterIcon />
              </Link>
              <Link href="#" color="inherit">
                <InstagramIcon />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" color="inherit" sx={{ mt: 4 }}>
          © {new Date().getFullYear()} StashSpace. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};
