import { Typography, TypographyProps } from "@mui/material";

export const SecondaryWord = (props: TypographyProps) => {
  const { children, ...rest } = props;

  return (
    <Typography
      variant="inherit"
      color="secondary"
      display="inline"
      fontWeight="bold"
      {...rest}
    >
      {children}
    </Typography>
  );
};
