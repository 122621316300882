import { Box, Typography, Stack, Container, Button } from "@mui/material";
import { Footer, PrimaryWord, SignupDialog, StashSpace } from "../components";
import { useState } from "react";

export const FAQ = () => {
	const [open, setOpen] = useState(false);
	return (
		<Box>
			<StashSpace />
			<SignupDialog open={open} setOpen={setOpen} />
			<Container maxWidth="lg">
				<Stack mb={2} mx={0}>

					<Box>
						<Typography variant="h4" textAlign="center" mt={4}>
							Frequently Asked Questions
						</Typography>

						<Stack spacing={4} mt={4}>
							<Stack component={"section"} spacing={2}>
								<Typography variant="h4">When will you launch?</Typography>
								<Typography>
									We're working hard to launch as soon as possible.  We'll send you an email when we're ready.
								</Typography>
								<Button variant="contained" onClick={() => setOpen(true)}>Sign up for the waitlist</Button>
							</Stack>
							<Stack component={"section"} spacing={2}>
								<Typography variant="h4">Product</Typography>

								<Typography variant="h6">What is StashSpace?</Typography>
								<Typography>
									StashSpace takes your screenshots (or links, or digital items) and organizes them automatically.  Then, makes them searchable and shareable.
								</Typography>
								<Typography variant="h6">Why is this different from text search in Photos?</Typography>
								<Typography>
									It's kind of similar but, stuff in your <PrimaryWord>Stash</PrimaryWord> is related together.
									So, you might be searching for a screenshot, but find a link or a note that you forgot about.
								</Typography>
								<Typography variant="h6">How do I get started?</Typography>
								<Typography>
									Right now, you can sign up for the waitlist.  We'll send you an email when you can start using StashSpace.
								</Typography>
							</Stack>

							<Stack component={"section"} spacing={2}>
								<Typography variant="h4">Service</Typography>
								<Typography variant="h6">Will StashSpace be free to use?</Typography>
								<Typography>
									To try it out and play with the sample <PrimaryWord>Stash</PrimaryWord> we'll have a free tier.
									<PrimaryWord>Stash</PrimaryWord>-ing more stuff will require a subscription or credits depending on your needs.
								</Typography>
								<Typography variant="h6">How secure will my data be?</Typography>
								<Typography>
									We take security seriously.  We'll have more details as we get closer to launch.
									- Opt-in explicity approval during import
									- Sensitivity warning on import
									- Easy to delete <PrimaryWord>Stash</PrimaryWord> items
									- Local only option (future)
								</Typography>
								<Typography variant="h6">Can I access my Stash from any device?</Typography>
								<Typography>
									We Plan to have a web interface and mobile apps.  We'll have more details as we get closer to launch.
								</Typography>
							</Stack>

						</Stack>
					</Box>

				</Stack>
			</Container>
			<Footer />
		</Box>


	);
};